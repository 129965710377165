import { transient, autoinject, customElement, bindable, bindingMode } from 'aurelia-framework';
import { computedFrom, BindingEngine, Disposable } from 'aurelia-binding';
import { AutoCompleteService } from './autocomplete-service';
import { SearchType } from 'services/place-search/models/search-type.enum';
import { IPlaceSearchItem } from 'services/place-search/models/place-search-item.interface';
import { PlaceSearchService } from 'services/place-search/place-search-service';

@autoinject()
@transient()
@customElement('autocomplete')
export class AutoComplete {

	@bindable({ defaultBindingMode: bindingMode.twoWay }) public selected: any; // The item selected in suggestion list
	@bindable({ defaultBindingMode: bindingMode.twoWay }) public value: string; // The text value
	
	// If suggestions should open when input gains focus or not
	@bindable({ isBoolean: true }) public openOnFocus = false;

	@bindable public placeholder = 'Search'; // Default placeholder value  

	// Optional template for formatting of icon
	@bindable public marker: any;
	@bindable public markerData: any;
	@bindable public icon: any; 
	@bindable public hidefavorites: any;
	@bindable public requiredInput: boolean;
	@bindable public selectOnBlur = false; // The system selects the first suggestion from the list of items when user focuses out of input field
	protected isDone = false;
	@bindable public dataSource: any; // Function or Array
	@bindable public debounce = 200; // Default debounce millis
	@bindable public minLength = 2; // Default length of input before call to service
	@bindable public lastSearchesKey: string; // Key to use when storing last searches in local storage
	@bindable public lastSearchesMaxLength = 10; // The max lenght of items stored in last searches collection

	// Optional template for formatting of suggestions. If none provided, 
	// the item or or item[presentationProperty] will be used.
	// @bindable public template: any;
	@bindable public presentationProperty: string; // If complex content (the propertyName for presentation)

	protected loading: boolean;

	private selectedItem: IPlaceSearchItem;
	private items: IPlaceSearchItem[];
	private input: HTMLInputElement;
	private suggestionList: HTMLUListElement;
	private isBound: boolean;
	
	private selectedIndex: number;
	private sourceIsArray: boolean;
	private ignoreValueChange: boolean;
	private observer: Disposable;
	private searchTypes: SearchType[];

	private suggestionsAreShown: boolean;
	private favoritesAreShown: boolean;

	private favorites: IPlaceSearchItem[];

	private useLastSearches = false;
	private lastSearches: IPlaceSearchItem[];

	private keyCodes = {
		ENTER: 13,
		TAB: 9,
		ESCAPE: 27,
		UP: 38,
		DOWN: 40,
		BACKSPACE: 8,
		DELETE: 46
    };

	@bindable public textExtractor = (item: any): string => {
		// Default text extractor
		return this.getPresentationText(item);
	};

	@bindable public favoritesFilter = (items: IPlaceSearchItem[]): IPlaceSearchItem[] => {	
		// Default filter -> return all items	
		return items;
	};

	@bindable public lastSearchesFilter = (items: IPlaceSearchItem[]): IPlaceSearchItem[] => {		
		// Default filter -> return all items
		return items;
	};

	constructor(
		private element: Element, 
		private bindingEngine: BindingEngine, 
		private placeSearchService: PlaceSearchService,
		private autoCompleteService: AutoCompleteService) {
			
        this.searchTypes = [ SearchType.SITE, SearchType.ROAD, SearchType.PLACE, SearchType.COORDINATE, SearchType.TPLED, SearchType.TIMMERLED, SearchType.REKOMMENDERADLED, SearchType.FACITRUTT];
	}

	public getClass(item: IPlaceSearchItem): string {
		if (item?.type == 'Category') {
			return '';
		}

		switch(item.type) {
			case 'Place':
				return 'fa fa-map-signs';
			case 'Site':
				return 'fa fa-industry';
			case 'Road':
				return 'fa fa-road';
			default:
				return 'fa fa-map-marker';
		}

		
	}

	protected bind() {
		// Get a reference to input
		this.input = this.element.querySelector('input');
		this.suggestionList = this.element.querySelector('ul') as HTMLUListElement;
		this.sourceIsArray = Array.isArray(this.dataSource);

		this.observer = this.bindingEngine
			.propertyObserver(this, 'selected')
			.subscribe(() => {
				// To prevent round trip
				if (this.selected !== this.selectedItem) {
					this.setSelectedItem();
				}
			});

		this.isBound = true;
		
		this.favorites = this.autoCompleteService.getSearchItemsFromLocalStorage('favorites');

		if (this.lastSearchesKey) {
			this.useLastSearches = true;
			this.lastSearches = this.autoCompleteService.getSearchItemsFromLocalStorage(this.lastSearchesKey);
		}

		if (this.selected) {
			this.setSelectedItem();
		}
	}

	protected unbind() {
		this.observer.dispose();
	}

	protected formatItem(item) {
		const value = this.textExtractor(item);

		if (typeof value !== 'string') {
			return value;
		}
		const input = this.getCurrentInputvalue();

		const globalInsensitive = 'gi';
		const textMatcher = new RegExp(input, globalInsensitive);
		
		return value.replace(textMatcher, match =>
			`<strong>${match}</strong>`
		);
		
	}

	@computedFrom('isDone')
	protected get inputDone() {
		return this.isDone ? 'form-input-done' : '';
	}

	/**
	 * Shows or hides favorites in list
	 * @param forceClose 
	 */
	protected toggleFavoriteItems(forceClose = false): void {
		if (!forceClose && !this.suggestionsAreShown){
			this.items = this.favoritesToView();
			this.selectedIndex = this.items.length ? 1 : null;
			this.highlightItem(this.selectedIndex);
		}

		if (forceClose || !this.items || this.items.length < 1) {
			this.suggestionsAreShown = false;
		}
		else {
			this.suggestionsAreShown = !this.suggestionsAreShown;
		}

		this.favoritesAreShown = this.suggestionsAreShown;

		if (this.favoritesAreShown) {
			this.selectedIndex = 1;
		}		
	}

	protected onFocus() {
		this.showItems(false);
	}

	protected onBlur() {

		// The list of items must be visible and user must have written some text (do not trigger on empty input)
		if (this.selectOnBlur && this.suggestionsAreShown && this.value) {

			const item = this.items.find(x => this.searchTypes.indexOf(x.type) > -1)
			if (item) {
				this.selectedItem = item;
				this.selectItem(this.selectedItem);
			}
		}

		this.hideItems();
	}

	/**
	 * key up events for the input element
	 * @param e 
	 */
	protected onKeyUp(e: KeyboardEvent) {
		const keyCode = e.keyCode;

		if (keyCode !== this.keyCodes.ESCAPE) {
			return;
		}

		if (this.suggestionsAreShown) {
			// Stop event from bubbeling
			e.stopPropagation();
		}

		this.hideItems();

		return true;
	}

	/**
	 * key events for the input element
	 * @param e 
	 */
	protected onKeyDown(e: KeyboardEvent) {
		const keyCode = e.keyCode;

		if (keyCode === this.keyCodes.BACKSPACE || keyCode === this.keyCodes.DELETE) {
			
			if (this.selected && this.selectedItem) {
				this.ignoreValueChange = true;
				this.selected = null;
				this.selectedItem = null;
				this.isDone = false;

				this.triggerUnselectEvent();
			}
			
			return true;
		}

		if (!this.suggestionsAreShown) {
			// We are not interested in key events here
			return true;
		}

		
		if (keyCode === this.keyCodes.ESCAPE) {
			// This will be captured in onKeyUp
			return;
		}

		

		// Check if Up, Down, Enter/Tab or Escape
		// Else just let it be -> wait for value change        
		switch (keyCode) {
			case this.keyCodes.ENTER:
				this.selectItem(this.selectedItem);
				break;
			case this.keyCodes.TAB: {

				this.selectItem(this.selectedItem);

				e.stopPropagation();
				e.preventDefault();
				break;
			}
			case this.keyCodes.UP: {
				// decrease index by one and select item in list
				this.selectedIndex--;
				if (this.selectedIndex < 1) {
					this.selectedIndex = 1;
				}
				if (this.items[this.selectedIndex].type === SearchType.CATEGORY) {
					this.selectedIndex--;
				}
				this.highlightItem(this.selectedIndex);
				e.preventDefault();
				break;
			}
			case this.keyCodes.DOWN: {
				// increase index by one and select item in list
				this.selectedIndex++;
				if (this.selectedIndex >= this.items.length) {
					this.selectedIndex = this.items.length - 1;
				}
				if (this.items[this.selectedIndex].type === SearchType.CATEGORY) {
					this.selectedIndex++;
				}
				this.highlightItem(this.selectedIndex);
				e.preventDefault();
				break;
			}
			default:
				break;
		}
		return true;
	}

	protected async setToFavorite(choosenFavorite: IPlaceSearchItem) {
		choosenFavorite.isFavorite = true;

		const alreadyAdded = this.favorites.some(x => x.name === choosenFavorite.name);
		if (alreadyAdded) {
			return;
		}

		this.favorites.push(choosenFavorite);
		this.favorites = this.placeSearchService.sortPlaceSearchItemsByName(this.favorites);

		this.autoCompleteService.saveSearchItemsToLocalStorage(this.favorites, 'favorites');
	}

	protected async delFavorite(favorite: IPlaceSearchItem) {
		favorite.isFavorite = false;

		const toSave = this.favorites.filter(x => x.name !== favorite.name);
		this.favorites = this.placeSearchService.sortPlaceSearchItemsByName(toSave);
		this.autoCompleteService.saveSearchItemsToLocalStorage(this.favorites, 'favorites');

		if (this.favoritesAreShown) {
			this.items = this.favoritesToView();
		}
	}

	/**
	 * Magic function that gets called after user input (naming convention)
	 */
	protected async valueChanged() {
		if (this.ignoreValueChange) {
			// We have set this value ourselves
			this.ignoreValueChange = false;
			return;
		}

		// Resetting the selected value before calling service for suggestions
		this.selectedItem = null;
		this.selected = undefined;
		this.items = [];

		if (!this.value || this.value.length < this.minLength) {
			if (!this.useLastSearches) {
				return;
			}

			this.items = this.lastSearchesToView();
		}
		else {
			this.loading = true;
			try {
				const searchItems = await this.getSearchItems();
				this.loading = false;
				this.items = searchItems;
			}
			catch(error) {
				this.loading = false;
			}
		}

		if (this.items && this.items.length > 0) {
			this.showItems(true);
		}
		else {
			this.hideItems();
		}
	}

		/**
	 * 
	 * @param show Force the items list to open if there are any suggestions
	 */
	private showItems(show: boolean) {
		if (!show) {
			if (this.useLastSearches) {
				if (!this.input.value || this.input.value.length < this.minLength) {
					this.items = this.lastSearchesToView();
					show = true;
				}
				
			}
		}

		const shouldShow = show || this.openOnFocus;

		if (shouldShow && this.items && this.items.length) {
			this.suggestionsAreShown = true;
			this.selectedIndex = this.items.length ? 1 : null;
			this.highlightItem(this.selectedIndex);
		}
	}

	private hideItems() {
		this.suggestionsAreShown = false;
	}

	private highlightItem(idx: number) {
		this.selectedItem = this.items[idx];
		// Ensure that the current selected item is visible if scrolling is in action
		if (this.suggestionList.children[0]) {
			const element: HTMLElement = (this.suggestionList.children[0].children[idx] as HTMLElement);
			if (!element) {
				return;
			}

			const offsetTop = element.offsetTop;
			const offsetHeight = element.offsetHeight;
			if (this.suggestionList.scrollHeight > this.suggestionList.offsetHeight) {
				if (offsetTop + offsetHeight * 2 > this.suggestionList.scrollTop + this.suggestionList.offsetHeight) {
					this.suggestionList.scrollTop = offsetTop - this.suggestionList.offsetHeight + offsetHeight * 2;
				}
				if (offsetTop - offsetHeight < this.suggestionList.scrollTop) {
					this.suggestionList.scrollTop = offsetTop - offsetHeight;
				}
			}
		}
	}

	private async selectItem(item) {

		this.selectedItem = item;
		this.selected = this.selectedItem;
		this.hideItems();

		// We should ignore next event for change on input since we are responsible for the change
		this.ignoreValueChange = true;

		if (this.useLastSearches) {
			this.saveToLastSearches(this.selected);
		}

		const presentationText = this.textExtractor(this.selectedItem);
		this.input.value = presentationText;
		
		const selectEvent = new CustomEvent('item-selected', {
			bubbles: true,
			detail: this.selectedItem
		});

		if (this.requiredInput) {
			this.isDone = true;
		}

		this.element.dispatchEvent(selectEvent);
	}

	private async triggerUnselectEvent() {

		setTimeout(() => {
			const unselectEvent = new CustomEvent('item-unselected', {
				bubbles: true,
				detail: this.selectedItem
			});
	
			this.element.dispatchEvent(unselectEvent);
		}, 50);
		
	}

	private async getSearchItems(): Promise<IPlaceSearchItem[]> {
		let ret: IPlaceSearchItem[] = [];
		const searchItems = await this.callService(this.value);

		// Check if any of the suggestions are marked as favorite in local storage
		this.searchTypes.forEach(element => {
			const searchTypeFilteredItems = this.searchTypeFilter(searchItems, element);
			if (searchTypeFilteredItems && searchTypeFilteredItems.length > 0) {
				ret = ret.concat(searchTypeFilteredItems);
			}
		});

		this.autoCompleteService.removeUnusedCategories(ret, this.searchTypes);

		return ret;
	}

	/**
	 * When selected has changed outside this component
	 */
	private setSelectedItem() {
		if (this.isBound) {
			this.selectedItem = this.selected;

			let presentationText = '';
			if (this.selectedItem) {
				presentationText = this.textExtractor(this.selectedItem);
				
				if (this.requiredInput && presentationText){
					this.isDone = true;
				}
				else if (!presentationText) {
					this.isDone = false;
				}
			}
			else {
				if (!this.requiredInput){
					this.isDone = false;
				}
			}

			this.input.value = presentationText;
		}
	}

	/**
	 * Get items from bound service (array or function)
	 * @param q The search term
	 */
	private async callService(q: string): Promise<any[]> {

		if (this.sourceIsArray) {
			const array = this.dataSource as any[];
			return Promise.resolve(
				array.filter(item => {
					const presentationValue = this.textExtractor(item);
					return this.autoCompleteService.contains(presentationValue, q);
				})
			);
		}
		else {
			const result = await this.dataSource(q);
			const currentinputvalue = this.getCurrentInputvalue();
			if (currentinputvalue !== q) {
				// Input has changed since this call was made 
				// -> just return and let the next request finish
				return [];
			}

			return result;
		}
	}

	/**
	 * Returns the immediate value for the input element
	 */
	private getCurrentInputvalue(): string {
		return this.input.value;
	}

	/**
	 * If a presentationProperty is specified -> return the value for the presentationProperty
	 * Otherwise just return the item
	 * @param item 
	 */
	private getPresentationText(item): string {
		if (item && this.presentationProperty) {
			return item[this.presentationProperty];
		}

		return item;
	}

	private searchTypeFilter(array: IPlaceSearchItem[], value: SearchType): IPlaceSearchItem[] {
		if (!array || array.length <= 0) {
			return array;
		}

		let filteredArray = array.filter(x => x.type === value);

		filteredArray.forEach(filterdItem => {
			const match = this.favorites.findIndex(x => x.name === filterdItem.name);
			if (match !== -1){
				filterdItem.isFavorite = true;
			}
		});

		filteredArray = this.placeSearchService.sortPlaceSearchItemsByFavoriteAndName(filteredArray);

		if (filteredArray.length > 0) {
			filteredArray.unshift({id: "",  name: this.autoCompleteService.translateType(value), type: SearchType.CATEGORY });
		}

		return filteredArray;
	}

	private saveToLastSearches(item: IPlaceSearchItem) {

		if (this.lastSearches && this.lastSearches.length > 0) {
			this.lastSearches = this.lastSearches.filter(x => x.name !== item.name);
		}

		this.lastSearches.push(item);

		if (this.lastSearches.length > this.lastSearchesMaxLength) {
			this.lastSearches.shift();
		}

		this.lastSearches = this.placeSearchService.sortPlaceSearchItemsByName(this.lastSearches);
		this.autoCompleteService.saveSearchItemsToLocalStorage(this.lastSearches, this.lastSearchesKey);
	}

	private favoritesToView(): IPlaceSearchItem[] {
		const clonedItems = this.autoCompleteService.clone(this.favorites);

		// Calling bound function to filter visible items
		const filteredItems = this.favoritesFilter(clonedItems);

		return this.autoCompleteService.favoritesToView(filteredItems);
	}

	private lastSearchesToView(): IPlaceSearchItem[] {
		const clonedItems = this.autoCompleteService.clone(this.lastSearches);

		// Calling bound function to filter visible items
		const filteredItems = this.lastSearchesFilter(clonedItems);

		filteredItems.forEach(filterdItem => {
			const match = this.favorites.findIndex( x => x.name === filterdItem.name);
			if (match !== -1) {
				filterdItem.isFavorite = true;
			}
		});

		return this.autoCompleteService.lastSearchesToView(filteredItems);
	}
}
