import * as L from 'leaflet';

let MousePositionControl = L.Control.extend({
	_crs: undefined,

	options: {
		position: 'bottomleft',
		separator: ' || ',
		emptyString: 'Unavailable',
		lngFirst: true,
		numDigits: 5,
		lngFormatter: undefined,
		latFormatter: undefined,
		prefix: ''
	},

	onAdd: function (map: L.Map) {
		this._crs = map.options.crs;

		this._container = L.DomUtil.create('div', 'leaflet-control-mouseposition');
		this._container.id = 'leaflet-mouseposition';
		this._container.style = 'display:none';
		L.DomEvent.disableClickPropagation(this._container);
		map.on('mousemove', this._onMouseMove, this);
		this._container.innerHTML = this.options.emptyString;
		return this._container;
	},

	onRemove: function (map) {
		map.off('mousemove', this._onMouseMove)
	},

	_onMouseMove: function (e) {
		this._container = L.DomUtil.get('leaflet-mouseposition');
		this._container.style = 'display:box';
		let lng = this.options.lngFormatter ?
						this.options.lngFormatter(e.latlng.lng) :
						L.Util.formatNum(e.latlng.lng, this.options.numDigits);
		let lat = this.options.latFormatter ?
						this.options.latFormatter(e.latlng.lat) :
						L.Util.formatNum(e.latlng.lat, this.options.numDigits);
		let point = this._crs.project(new L.LatLng(lat, lng));
		let value = this.options.lngFirst ? 'X: ' + point.x.toFixed(0) + this.options.separator + 'Y: ' + point.y.toFixed(0) :
		'Y:' + point.y.toFixed(0) + this.options.separator +  'X:' + point.x.toFixed(0);
		let prefixAndValue = this.options.prefix + ' ' + value;
		this._container.innerHTML = prefixAndValue;
	}
});

L.Map.mergeOptions({
	positionControl: false
});

L.Map.addInitHook(function () {
	if (this.options.positionControl) {
			this.positionControl = new MousePositionControl();
			this.addControl(this.positionControl);
	}
});

export default MousePositionControl;
