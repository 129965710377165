import { IPlaceSearchItem } from "services/place-search/models/place-search-item.interface";
import { SearchType } from "services/place-search/models/search-type.enum";

export class AutoCompleteService {

    public clone(items: IPlaceSearchItem[]): IPlaceSearchItem[]{
        const clone = JSON.parse(JSON.stringify(items));

        return clone;
    }

    /**
	 * If a string contains a sub string (case insensitive)
	 * @param value The text to search
	 * @param q The query
	 */
	public contains(value: string, q: string): boolean {
		const trimmedValue = value.replace(' ', '').toLowerCase();
		const trimmedQ = q.replace(' ', '').toLowerCase();

		return !(trimmedValue.indexOf(trimmedQ) < 0);
	}

	public translateType(type: SearchType): string {
		if (type === SearchType.SITE) {
			return 'Mottagningsplatser';
		}
		else if (type === SearchType.ROAD) {
			return 'Vägar';
		}
		else if (type === SearchType.REKOMMENDERADLED){
			return 'Rekommenderad Led';
		}
		else if(type === SearchType.FACITRUTT){
			return 'Facitrutt';
		}
		else if (type === SearchType.PLACE) {
			return 'Platser';
		}
		else if (type === SearchType.COORDINATE) {
			return 'Koordinat';
		}
		else if (type === SearchType.TPLED) {
			return 'TP-Led';
		}
		else if (type === SearchType.TIMMERLED) {
			return 'Timmerled';
		}
		else if (type === SearchType.FAVORITES) {
			return 'Favoriter';
		}
		else if (type === SearchType.LAST_SEARCHES) {
			return 'Senaste sökningarna';
		}
		else {
			return 'typ inte implementerad';
		}
	}

    public removeUnusedCategories(searchItems: IPlaceSearchItem[], searchTypes: SearchType[]): void {
		searchTypes.forEach(searchType => {
			const index = searchItems.findIndex(x => x.type === searchType);
			if (index === -1) {
				const category = searchItems.findIndex(x => x.name === this.translateType(searchType));
				if (category !== -1) {
					searchItems.splice(category, 1);
				}
			}
		});
    }

    public favoritesToView(items: IPlaceSearchItem[]): IPlaceSearchItem[] {
		const clone = this.clone(items);
		
		if (clone.length > 0) {
			clone.unshift({id: "", name: this.translateType(SearchType.FAVORITES), type: SearchType.CATEGORY });
		}

		return clone;
    }
    
    public lastSearchesToView(items: IPlaceSearchItem[]): IPlaceSearchItem[] {
		const clone = JSON.parse(JSON.stringify(items));
		
		if (clone.length > 0) {
			clone.unshift({ name: this.translateType(SearchType.LAST_SEARCHES), type: SearchType.CATEGORY });
		}

		return clone;
	}

	public getSearchItemsFromLocalStorage(key: string): IPlaceSearchItem[] {
		const ret: IPlaceSearchItem[] = [];
		if (localStorage[key]) {
			const items = JSON.parse(localStorage.getItem(key));
			if (items.length > 0) {
				items.forEach(element => {
					ret.push(element);
				});
			}
		}

		return ret;
    }
    
    public saveSearchItemsToLocalStorage(items: IPlaceSearchItem[], key: string): void {
		localStorage.setItem(key, JSON.stringify(items));
    }
}