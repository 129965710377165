import {Aurelia, LogManager, } from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import { ConsoleAppender } from 'aurelia-logging-console';
import { HttpClient } from 'aurelia-fetch-client';
import { OAuthService, OAuthTokenService } from 'aurelia-oauth';
import { EnvironmentConfiguration } from 'services/configuration/services/configuration';

export function configure(aurelia: Aurelia): void {
  // Register and initialize configuration.
  aurelia.container.registerSingleton(EnvironmentConfiguration);
  const myConfiguration = aurelia.container.get(EnvironmentConfiguration);
  myConfiguration.initialize();

  const httpClient = aurelia.container.get(HttpClient) as HttpClient;
  aurelia.use
  .standardConfiguration()
  .developmentLogging()
  .feature(PLATFORM.moduleName('resources/index'))
  .plugin(PLATFORM.moduleName('aurelia-dialog'))
  .plugin(PLATFORM.moduleName('proj4leaflet'))
  .plugin(PLATFORM.moduleName('leaflet-easybutton'))
  .plugin(PLATFORM.moduleName('leaflet-extensions/mouse-position-control'))
  .plugin(PLATFORM.moduleName('leaflet-extensions/box-select'))
  .plugin(PLATFORM.moduleName('leaflet-contextmenu'))
  .plugin(PLATFORM.moduleName('leaflet.markercluster'))
  .plugin(PLATFORM.moduleName('leaflet-draw'))
  .plugin(PLATFORM.moduleName('leaflet-polylinedecorator'))
  .plugin(PLATFORM.moduleName('leaflet.polylinemeasure'))
  .plugin(PLATFORM.moduleName('aurelia-dialog'))
  // .plugin(PLATFORM.moduleName('bluebird'))
  .plugin(PLATFORM.moduleName('jquery'))
  // .plugin(PLATFORM.moduleName('aurelia-configuration'), (config: AureliaConfiguration) => {
  //   // Prevent config file to be cached by the browser
  //   config.setConfig('config.json');

  //   // Not specifying prod -> defaults to prod
  //   config.setEnvironments({
  //     local: ['localhost', '127.0.0.1'],
  //     triona_viol2: ['vagval-viol2.triona.se'],
  //     triona_viol3: ['vagval-viol3.triona.se'],
  //     test: ['vagval.test.sdc.se', 'sdc-vagval-test.azurewebsites.net'],
  //     ktest1:['vagval.ktest1.biometria.se', 'biometria-vagval-ktest1.azurewebsites.net'],
  //     test_viol3: ['vagval.test3.sdc.se', 'biometria-vagval-test-viol3.azurewebsites.net'],
  //     sit1: ['vagval.sit1.sdc.se', 'sdc-vagval-sit1.azurewebsites.net'],
  //     acc: ['vagval.acctest.biometria.se', 'biometria-vagval-acctest.azurewebsites.net'],
  //     test3_viol2: ['vagval-viol2.test3.sdc.se', 'biometria-vagval-test3-viol2.azurewebsites.net']
  //         });
  // })
  .plugin(PLATFORM.moduleName('aurelia-oauth'), (oauthService, oauthTokenService, configureClient) => {
    configureOauth(oauthService, oauthTokenService, configureClient, httpClient, myConfiguration)
  });


  // Register vv features globally (no need for require)
  aurelia.use
  .feature(PLATFORM.moduleName('features/aurelia-leaflet/index'))
  .feature(PLATFORM.moduleName('features/autocomplete/index'));
  
  aurelia.start()
  .then(() => {
    aurelia.setRoot(PLATFORM.moduleName('app'));
    LogManager.addAppender(new ConsoleAppender());
    LogManager.setLevel(LogManager.logLevel[myConfiguration.env.LogLevel]);
  });
}


function configureOauth(
  oauthService: OAuthService, 
  oauthTokenService: OAuthTokenService, 
  configureClient: (client: any) => void, 
  client: HttpClient,
  configuration: any) {

    // Depending on environment (dev/stage/prod) get the correct config options
    const azureConfig = configuration.environment.AzureAuth;
    const loginUrl = `https://login.microsoftonline.com/${azureConfig.Tenant}/oauth2/authorize`;
    const logoutUrl = `https://login.microsoftonline.com/${azureConfig.Tenant}/oauth2/v2.0/logout`;

    oauthService.configure({
      loginUrl: loginUrl,
      logoutUrl: logoutUrl,
      clientId: azureConfig.Audience,
      alwaysRequireLogin: azureConfig.AlwaysRequireLogin
    });

    configureClient(client);
}
