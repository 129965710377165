import * as L from 'leaflet';

export const BoxSelect = L.Handler.extend({

    initialize: function (map) {
		this._map = map;
		this._container = map._container;
		this._pane = map._panes.overlayPane;
        this._resetStateTimeout = 0;
        this._mapDraggable = false;

        map.boxZoom = this;
        map.on('unload', this._destroy, this);
        
        // Super ugly hack on map -> we don´t want click event to propagate when doing ctr select
        // map._draggableMoved = (obj) => {
        //     obj = obj.dragging && obj.dragging.enabled() ? obj : map;
        //     return (obj.dragging && obj.dragging.moved()) || (map.boxZoom && map.boxZoom.moved()) || this.moved();
        // }
	},

	addHooks: function () {
		L.DomEvent.on(this._container, 'mousedown', this._onMouseDown, this);
	},

	removeHooks: function () {
		L.DomEvent.off(this._container, 'mousedown', this._onMouseDown, this);
	},

	moved: function () {
		return this._moved;
	},

	_destroy: function () {
		L.DomUtil.remove(this._pane);
		delete this._pane;
	},

	_resetState: function () {
		this._resetStateTimeout = 0;
		this._moved = false;
	},

	_clearDeferredResetState: function () {
		if (this._resetStateTimeout !== 0) {
			clearTimeout(this._resetStateTimeout);
			this._resetStateTimeout = 0;
		}
	},

	_onMouseDown: function (e) {
		if (!e.ctrlKey || ((e.which !== 1) && (e.button !== 1))) { 
            return false; 
        }

        this._mapDraggable = this._map.dragging.enabled();

        // Temporary disable map dragging
        if (this._mapDraggable ) {
            this._map.dragging.disable();
        }

		// Clear the deferred resetState if it hasn't executed yet, otherwise it
		// will interrupt the interaction and orphan a box element in the container.
		this._clearDeferredResetState();
		this._resetState();

		L.DomUtil.disableTextSelection();
		L.DomUtil.disableImageDrag();

		this._startPoint = this._map.mouseEventToContainerPoint(e);

		L.DomEvent.on(document as any, {
			contextmenu: L.DomEvent.stop,
			mousemove: this._onMouseMove,
			mouseup: this._onMouseUp,
			keydown: this._onKeyDown
        }, this);

        L.DomEvent.stopPropagation(e);
        return true;
	},

	_onMouseMove: function (e) {
		if (!this._moved) {
			this._moved = true;

			this._box = L.DomUtil.create('div', 'leaflet-zoom-box', this._container);
			L.DomUtil.addClass(this._container, 'leaflet-crosshair');
		}

		this._point = this._map.mouseEventToContainerPoint(e);

		const bounds = new L.Bounds(this._point, this._startPoint);
		const size = bounds.getSize();

        L.DomUtil.setPosition(this._box, bounds.min);

		this._box.style.width  = size.x + 'px';
		this._box.style.height = size.y + 'px';
	},

	_finish: function () {
		if (this._moved) {
			L.DomUtil.remove(this._box);
			L.DomUtil.removeClass(this._container, 'leaflet-crosshair');
		}

		L.DomUtil.enableTextSelection();
		L.DomUtil.enableImageDrag();

		L.DomEvent.off(document as any, {
			contextmenu: L.DomEvent.stop,
			mousemove: this._onMouseMove,
			mouseup: this._onMouseUp,
			keydown: this._onKeyDown
        }, this);
        
        // Enable map dragging again
        if (this._mapDraggable ) {
            this._map.dragging.enable();
        }
	},

	_onMouseUp: function (e) {
		if ((e.which !== 1) && (e.button !== 1)) { return; }

		this._finish();

        if (!this._moved) { return; }
        
        L.DomEvent.stopPropagation(e);

        // Postpone to next JS tick so internal click event handling
		// still see it as "moved".
		this._clearDeferredResetState();
		this._resetStateTimeout = setTimeout(L.Util.bind(this._resetState, this), 0);

		const bounds = new L.LatLngBounds(
			this._map.containerPointToLatLng(this._startPoint),
			this._map.containerPointToLatLng(this._point));

        this._map.fire('boxselectend', {boxSelectBounds: bounds});
	},

	_onKeyDown: function (e) {
		if (e.keyCode === 27) {
			this._finish();
		}
	}

});