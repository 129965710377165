import { autoinject, bindable } from 'aurelia-framework';

import * as flatpickr from "flatpickr";

import { Swedish } from "./sv"

@autoinject()
export class DatePicker {
	@bindable date: string;
	@bindable minDate: string;
	@bindable placeholder: string;
	@bindable classBind: string;

    private dateFormat: string = 'Y-m-d';
    private picker: flatpickr.default.Instance;
    private isOpen: boolean = false;
    private closeTimestamp: number;

    constructor(private element: Element) {
    }

    attached(): void {
		if (this.date) {
			this.date = this.formatDateString(this.date.toString());
		}

		if (this.minDate) {
			this.minDate = this.formatDateString(this.minDate.toString());
		}

		const input = this.element.querySelector('input');
        this.picker = (flatpickr as any)(input, {
			defaultDate: this.date,
			minDate: this.minDate,
            dateFormat: this.dateFormat,
            enableTime: false,
			locale: Swedish,
            onChange: (args) => {
				let dateChangedEvent = null;
				if (args.length === 0) {
					dateChangedEvent = new CustomEvent('date-changed', {
						bubbles: true,
						detail: null
					});
				}
				else {
					const date = new Date(args);
					dateChangedEvent = new CustomEvent('date-changed', {
						bubbles: true,
						detail: date
					});
				}
				this.element.dispatchEvent(dateChangedEvent);
            },
            onOpen: () => {
                this.isOpen = true;
            },
            onClose: () => {
                this.isOpen = false;
                this.closeTimestamp = new Date().getTime();
			}
        });
    }
    
    detached() {
        if (this.picker) {
            this.picker.destroy();
        }
    }

    toggle(): void {
        const initial = this.isOpen;

        const insideTolerance = () => {
            if (!this.closeTimestamp) {
                return true;
            }

            const now = new Date().getTime();
            return (now - this.closeTimestamp) > 500;
        };

        setTimeout(() => {
            if (initial === this.isOpen && insideTolerance()) {
                this.picker.toggle();
            }

            this.closeTimestamp = undefined;
        }, 50);
	}

	removeDate($event){
		$event.stopPropagation();
		this.picker.clear();
	}

	private formatDateString(date: string): string {
		const stringYear = date.substring(0, 4);
		const stringMonth = date.substring(4, 2);
		const stringDay = date.substring(6, 2);
		return stringYear +'-'+ stringMonth +'-'+ stringDay;
    }
}
