import { autoinject } from "aurelia-framework";
import { DialogService } from 'aurelia-dialog';
import { Interceptor } from 'aurelia-fetch-client';

import { Alert } from 'components/common/dialog/alert';

@autoinject()
export class ExceptionInterceptor implements Interceptor {


	/**
	 * This interceptor checks if responses are Exceptions and shows ExceptionMessages in alert dialog
	 * @param dialogService 
	 */
	constructor(private dialogService: DialogService) {
		
	}

	/**
	 * 
	 * @param response Checks for responses <> 200 and shows message in alert dialog if Exception
	 */
	async response(response) {
		if (!response.ok && this.canShowDialog()) {
			await response.json().then((serverError: any) => {
				if (serverError.ExceptionType) {
					const model = {
						ErrorMessage: serverError.ExceptionMessage,
						Mode: "error"
					};
			
					this.dialogService.open({viewModel: Alert, model: model});
				}

				return Promise.reject<Response>(serverError);
			});
		}
		else {
			return response;
		}
	}

	/**
	 * Will return false if there is already a dialog opened
	 */
	private canShowDialog(): boolean {
		return !(this.dialogService.hasOpenDialog || this.dialogService.hasActiveDialog);
	}

}